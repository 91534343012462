"use client";

import { useCallback, useEffect } from "react";
import { Card, Spin } from "antd";
import {
  IPagination,
  ISorting,
  RouteNames,
  useAllPlayersPage,
  useUserModal,
} from "@finbackoffice/backoffice-core-next";
import { PlayersTable } from "./PlayersTable";
import { PlayersFilters } from "./PlayersFilters";
import { IUserSummaryProjection } from "@finbackoffice/adminbff-client";
import { useSearchParams } from "next/navigation";
import "./players.sass";

type IPlayersProps = {
  isTopSearch?: boolean;
  search?: string;
  topPagination?: IPagination;
  showFilters?: boolean;
};

const Players: React.FC<IPlayersProps> = ({
  search,
  topPagination,
  isTopSearch = false,
  showFilters = true,
}) => {
  const {
    setSorting,
    setPagination,
    filter,
    handleFilterChange,
    handleBaseCurrencyChange,
    baseCurrency,
    playersState,
    sorting,
    pagination,
    totalItems,
  } = useAllPlayersPage({ topPagination, searchQuery: search });
  const searchParams = useSearchParams();
  const { openUserModal } = useUserModal();
  const handleTableChange = useCallback(
    (pagination: IPagination, sorter: ISorting<IUserSummaryProjection>) => {
      setSorting({
        field: sorter.field,
        order: sorter.order,
      });
      setPagination(pagination);
    },
    [setPagination, setSorting],
  );

  useEffect(() => {
    const userId = searchParams?.get("user_id");
    if (userId) {
      const decoded = decodeURIComponent(userId);
      openUserModal({
        user_id: +decoded,
        redirect: `/${RouteNames.PLAYERS_CONTROL}/${RouteNames.PLAYERS}`,
      });
    }
  }, []);

  return (
    <section id="players-page" className="container">
      <Card data-testid="players-page">
        {showFilters ? (
          <PlayersFilters
            filter={filter}
            onChange={handleFilterChange}
            onBaseCurrencyChange={handleBaseCurrencyChange}            
            accountStatus={filter?.accountStatus}
            baseCurrency={baseCurrency}
            registrationDateFrom={filter?.registrationDate?.from}
            registrationDateTo={filter?.registrationDate?.to}
          />
        ) : null}
        <Spin spinning={playersState.loading}>
          <PlayersTable
            isTopSearch={isTopSearch}
            currency={baseCurrency}
            players={playersState.players}
            sorting={sorting}
            pagination={{ ...pagination, total: totalItems }}
            onTableChange={handleTableChange}
          />
        </Spin>
      </Card>
    </section>
  );
};

export default Players;
