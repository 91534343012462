import React, {FC, useCallback, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  useWebsocketUpdates,
} from "@finbackoffice/backoffice-core-next";
import moment from "moment/moment";
import "./header-server-time.sass";

export const HeaderServerTime: FC = () => {
  const { t } = useTranslation();
  const [serverTime, setServerTime] = useState<string>();

  const handleWebsocketUpdates = useCallback(
    ({ time }: { time: string }) => {
      setServerTime(moment.utc(time).format("DD MMM HH:mm"));
    },
    []
  );

  useWebsocketUpdates("ping", handleWebsocketUpdates, true);

  return (
    <div className="server-time">
      {serverTime && (
        <>
          <div>{t("common.server-time")}</div>
          <div>{serverTime}</div>
        </>
      )}
    </div>
  );
};
