import React, { FC, useContext, useRef, useState } from "react";
import { Button, Card, Form, Select, Spin, Tooltip } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { IUpdateBetRequest } from "@finbackoffice/adminbff-client";
import { AdminClientContext } from "@finbackoffice/backoffice-core-next";
import { ITicketResponse } from "@finbackoffice/adminbff-client/dist/interface";
import { TicketStatus } from "@finbackoffice/enums";

const { Option } = Select;
type IBetsTableDetailsProps = {
  editable?: boolean;
  isMtsOnly?: boolean;
  data: ITicketResponse;
  updateRecord?: (id: string, record: ITicketResponse) => void;
};
export const BetsTableDetails: FC<IBetsTableDetailsProps> = ({
  editable = false,
  data,
  updateRecord,
  isMtsOnly,
}) => {
  const adminBFFClient = useContext(AdminClientContext);
  const [editForm, setEditForm] = useState(false);
  const { t } = useTranslation();
  const betsRef: React.MutableRefObject<IUpdateBetRequest[]> = useRef([]);

  const updateBetsData = async () => {
    try {
      const response = await adminBFFClient.updateTicket(data.id, {
        bets: betsRef.current,
      });

      setEditForm(false);
      updateRecord?.(data.id, response);
      betsRef.current = [];
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <Card data-testid="bets-list" className="bets-list-espanded-row">
      <div className="bet-details-header">
        <strong>
          {t("bets.main_id")}: {data.id}
        </strong>
        <i className="vertical-separator" />
        {t("player.transactions.column.operation")}:{" "}
        {t(`player.bets.betType.${data?.type?.toLowerCase()}`)}
        <i className="vertical-separator" />
        {t("bets.created")}: {moment(data.created_at).format("MM/DD/YY HH:mm")}
        <i className="vertical-separator" />
        {t("sport-control.game.market_group.status.settled")}:{" "}
        {data.closed_at ? (
          <span>{moment(data.closed_at).format("MM/DD/YY HH:mm")}</span>
        ) : (
          "- -"
        )}
        {editable && (
          <Form className="bets-control-edit-form">
            {editForm ? (
              <>
                <Form.Item
                  shouldUpdate
                  className="wallet-row-details-form-cancel"
                >
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      setEditForm(false);
                      betsRef.current = [];
                    }}
                  >
                    {t("common.cancel")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    danger
                    onClick={() => betsRef.current.length && updateBetsData()}
                  >
                    {t("common.save")}
                  </Button>
                </Form.Item>
              </>
            ) : (
              <Form.Item className="bets-control-edit-form-edit-btn">
                <div onClick={() => setEditForm(true)}>
                  <EditOutlined />{" "}
                  {t("player.wallet.crypto.withdrawal.address.edit")}
                </div>
              </Form.Item>
            )}
          </Form>
        )}
      </div>
      <Spin spinning={false}>
        <table className="ant-table ant-table-bordered">
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell">N</th>
              <th className="ant-table-cell">{t("bets.event")}</th>
              <th className="ant-table-cell">{t("bets.bet")}</th>
              <th className="ant-table-cell">{t("player.bets.odds")}</th>
              <th className="ant-table-cell" colSpan={2}>
                {t("common.result")}
              </th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            {data?.bets?.map((outcome, i: number) => (
              <React.Fragment key={outcome.event_id}>
                <tr>
                  <td
                    className="ant-table-cell bets-details-n-column"
                    rowSpan={2}
                  >
                    {i + 1}
                  </td>
                  <td className="ant-table-cell bets-details-event-column">
                    <span>
                      {t(
                        `player.bets.sportService.${outcome.event_status.toLowerCase()}`
                      )}
                      {` / `}
                      {`${outcome.sport_name} / `}
                      {`${outcome.category_name} / `}
                      {`${outcome.tournament_name} / `}
                    </span>
                    <span>
                      {t("sport-control.game.match-id")}: {outcome.event_id}
                    </span>
                    <span>{`${outcome.home_team} - ${outcome.away_team}`}</span>
                    <span>
                      {t("bets.event_date")}:{" "}
                      {moment(outcome.scheduled_at).format("MM/DD/YYYY HH:mm")}
                    </span>
                  </td>
                  <td className="ant-table-cell bets-details-bet-column">
                    <span>{outcome.market_name}</span>
                    <span>
                      {t("player.bets.pick")}: {outcome.outcome_name}
                    </span>
                  </td>
                  <td className="ant-table-cell bets-details-odds-column">
                    {outcome.odds}
                  </td>
                  <td className="ant-table-cell bets-details-results-column">
                    {editForm ? (
                      <Form>
                        <Form.Item noStyle>
                          <Select
                            defaultValue={outcome?.status}
                            onChange={(value) =>
                              onBetsStatusChangeHandler(outcome.id, value)
                            }
                          >
                            {Object.values(TicketStatus).map((status) => (
                              <Option key={status} value={status}>
                                {status}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form>
                    ) : (
                      <>{outcome?.status?.toLowerCase()}</>
                    )}
                  </td>
                  <td className="ant-table-cell bets-status-column">
                    <i
                      className={`bet-status bet-status-${outcome?.status?.toLowerCase()}`}
                    />
                    {editable && data.admin_name && (
                      <div className="bet-status-td-manual">
                        <i>M</i>
                        <span title={data.admin_name}>{data.admin_name}</span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={6} className="ant-table-cell">
                    {outcome.place_state && (
                      <>
                        <strong>{t("bets.placed_at")}: </strong>
                        {t("bets.main_score")}
                        {`: ${outcome.place_state.home_score}:${outcome.place_state.away_score}; `}
                        {t("bets.scores")}
                        {outcome.place_state.period_scores
                          ? `: (${outcome.place_state.period_scores?.map(
                              (period: any) =>
                                `${period.home_score}:${period.away_score}`
                            )}); `
                          : ": N/A; "}
                        {t("players.table.column.status")}:{" "}
                        {`${outcome.match_status}; `}
                        {outcome.place_state.balls !== undefined &&
                          `${t("bets.balls")}: ${outcome.place_state.balls}; `}
                        {outcome.place_state.strikes !== undefined &&
                          `${t("bets.strikes")}: ${
                            outcome.place_state.strikes
                          }; `}
                        {outcome.place_state.outs !== undefined &&
                          `${t("bets.outs")}: ${outcome.place_state.outs}; `}
                        {outcome.place_state.bases !== undefined &&
                          `${t("bets.bases")}: ${outcome.place_state.bases}; `}
                      </>
                    )}
                    {outcome.place_state.event_clock && (
                      <>
                        <i className="vertical-separator" />
                        {outcome.place_state.event_clock.event_time != null &&
                          `${t("bets.event_time")}: ${
                            outcome.place_state.event_clock.event_time
                          }; `}
                        {outcome.place_state.event_clock.remaining_date !=
                          null &&
                          `${t("bets.remaining_date")}: ${
                            outcome.place_state.event_clock.remaining_date
                          }; `}
                        {outcome.place_state.event_clock
                          .remaining_time_in_period != null &&
                          `${t("bets.remaining_time_in_period")}: ${
                            outcome.place_state.event_clock
                              .remaining_time_in_period
                          }; `}
                        {outcome.place_state.event_clock.stoppage_time !=
                          null &&
                          `${t("bets.stoppage_time")}: ${
                            outcome.place_state.event_clock.stoppage_time
                          }; `}
                        {outcome.place_state.event_clock
                          .stoppage_time_announced != null &&
                          `${t("bets.stoppage_time_announced")}: ${
                            outcome.place_state.event_clock
                              .stoppage_time_announced
                          }; `}
                        {outcome.place_state.event_clock.stopped != null &&
                          `${t("bets.stopped")}: ${
                            outcome.place_state.event_clock.stopped
                          }; `}
                      </>
                    )}
                    {outcome.close_state && (
                      <div>
                        <strong>{t("bets.settled_at")}: </strong>
                        {t("bets.main_score")}
                        {`: ${outcome.close_state.home_score}:${outcome.close_state.away_score}; `}
                        {t("bets.scores")}
                        {outcome.close_state.period_scores
                          ? `: (${outcome.close_state.period_scores?.map(
                              (period: any) =>
                                `${period.home_score}:${period.away_score}`
                            )}); `
                          : ": N/A; "}
                        {t("players.table.column.status")}:{" "}
                        {`${outcome.close_state.status} ;`}
                        {outcome.close_state.balls !== undefined &&
                          `${t("bets.balls")}: ${outcome.close_state.balls}; `}
                        {outcome.close_state.strikes !== undefined &&
                          `${t("bets.strikes")}: ${
                            outcome.close_state.strikes
                          }; `}
                        {outcome.close_state.outs !== undefined &&
                          `${t("bets.outs")}: ${outcome.close_state.outs}; `}
                        {outcome.close_state.bases !== undefined &&
                          `${t("bets.bases")}: ${outcome.close_state.bases}; `}
                        {outcome.close_state.event_clock && (
                          <>
                            <i className="vertical-separator" />
                            {outcome.close_state.event_clock.event_time !=
                              null &&
                              `${t("bets.event_time")}: ${
                                outcome.close_state.event_clock.event_time
                              }; `}
                            {outcome.close_state.event_clock.remaining_date !=
                              null &&
                              `${t("bets.remaining_date")}: ${
                                outcome.close_state.event_clock.remaining_date
                              }; `}
                            {outcome.close_state.event_clock
                              .remaining_time_in_period != null &&
                              `${t("bets.remaining_time_in_period")}: ${
                                outcome.close_state.event_clock
                                  .remaining_time_in_period
                              }; `}
                            {outcome.close_state.event_clock.stoppage_time !=
                              null &&
                              `${t("bets.stoppage_time")}: ${
                                outcome.close_state.event_clock.stoppage_time
                              }; `}
                            {outcome.close_state.event_clock
                              .stoppage_time_announced != null &&
                              `${t("bets.stoppage_time_announced")}: ${
                                outcome.close_state.event_clock
                                  .stoppage_time_announced
                              }; `}
                            {outcome.close_state.event_clock.stopped != null &&
                              `${t("bets.stopped")}: ${
                                outcome.close_state.event_clock.stopped
                              }; `}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
                {isMtsOnly || data.status === TicketStatus.Rejected ? (
                  <tr>
                    <td
                      colSpan={6}
                      className="ant-table-cell ant-table-cell-rejected-info"
                    >
                      {outcome.place_state && (
                        <>
                          <table className="ant-table ant-table-bordered">
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">
                                  {t("bets.code")}
                                </th>
                                <th className="ant-table-cell">
                                  {t("players.table.column.status")}
                                </th>
                                <th className="ant-table-cell">
                                  {t("bets.message")}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              <tr>
                                <td className="ant-table-cell">
                                  {data.mts_response.reason.code}
                                </td>
                                <td className="ant-table-cell">
                                  {data.status}
                                </td>
                                <td className="ant-table-cell">
                                  {data.mts_response.reason.message}
                                  <Tooltip
                                    placement={"right"}
                                    trigger={"click"}
                                    overlayClassName="info-tooltip"
                                    title={
                                      <ul>
                                        {convertObjToString(data.mts_response)}
                                      </ul>
                                    }
                                  >
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Spin>
    </Card>
  );

  function onBetsStatusChangeHandler(id: string, status: TicketStatus) {
    const index = betsRef.current.findIndex((item) => item.id === id);

    if (index === -1) {
      betsRef.current.push({ id, status });
    } else {
      betsRef.current.splice(index, 1, { id, status });
    }
  }

  function convertObjToString(mtsObj: any): JSX.Element[] {
    const items: JSX.Element[] = [];
    const getElements = (mtsObj: any, mts: string) => {
      if (["string", "number"].includes(typeof mtsObj[mts])) {
        items.push(
          <li>
            {mts}: {mtsObj[mts]}
          </li>
        );
      } else if (typeof mtsObj[mts] === "object") {
        if (mtsObj[mts]) {
          for (let key in mtsObj[mts]) {
            const converted: any = getElements(mtsObj[mts], key);
            converted && items.push(converted);
          }
        } else {
          items.push(<li>{mts}: null</li>);
        }
      }
    };
    Object.keys(mtsObj).forEach((mts) => {
      getElements(mtsObj, mts);
    });

    return items;
  }
};
