import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Currency } from "@finbackoffice/enums";
import { IPaymentsCurrencyConfigWithCurrency } from "@/providers";
import {
  AdminBFFClient,
  IExchangeRatePair,
} from "@finbackoffice/adminbff-client";
import {
  CurrencyIcon,
  CurrencyFormatterContext,
  PaymentsConfigContext,
  AdminClientContext,
  IconWalletTotal,
  IconGgr,
  TotalsContext,
  useTotalBalances,
  useCurrencyOptions,
  getFildNameWithCurrency,
} from "@finbackoffice/backoffice-core-next";
import IconUsersTotalBalances from "../../../../icons/users-total-balances.svg";
import { ISportBetHoldsProjection } from "@finbackoffice/adminbff-client/dist/interface";
import "./accounting-balances.sass";

type ILatestExchangeRatesData = { [key: string]: IExchangeRatePair } | null;

const AccountingBalances: FC = (): ReactElement => {
  const { t } = useTranslation();
  const adminBFFClient = useContext(AdminClientContext);
  const { getConfigs } = useContext(PaymentsConfigContext);
  const { formatCurrency } = useContext(CurrencyFormatterContext);
  const [loading, setLoading] = useState<boolean>(true);
  const totalsContext = useContext(TotalsContext);
  const [latestExchangeRatesData, setLatestExchangeRatesData] =
    useState<ILatestExchangeRatesData>(null);
  const [holdBets, setHoldBets] = useState<ISportBetHoldsProjection[]>([]);
  const totalBalances = useTotalBalances();
  const { defaultCurrency } = useCurrencyOptions();
  const cryptoCurrencies = getConfigs()
    .filter(
      (config) =>
        config.enabled ||
        (totalsContext.mainCryptoWallets?.totals?.[config.currency] &&
          parseFloat(
            totalsContext.mainCryptoWallets?.totals[config.currency].balance
          ) > 0)
    )
    .sort(
      (
        a: IPaymentsCurrencyConfigWithCurrency,
        b: IPaymentsCurrencyConfigWithCurrency
      ) => (a.confirmation_count || 1) - (b.confirmation_count || 1)
    )
    .map((config) => config.currency as Currency);

  useEffect(() => {
    load();
    totalsContext.subscribe();
    return () => {
      totalsContext.unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const holdBetsTotal = useMemo(
    () => holdBets.find((bets) => bets.currency === "Total"),
    [holdBets]
  );

  return (
    <Spin
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
      spinning={loading}
    >
      {totalsContext.mainCryptoWallets && totalsContext.userCryptoWallets && (
        <>
          <div className="accounting-balances-total">
            <div className="accounting-balances-main-wallet-total">
              <IconWalletTotal />
              <span className="accounting-balances-title">
                {t("accounting.balances.main-wallet-total")}
              </span>
              <div>
                <strong>
                  {latestExchangeRatesData &&
                    formatCurrency(
                      totalsContext.mainCryptoWallets!.totalUsd *
                        parseFloat(
                          latestExchangeRatesData[defaultCurrency].rate
                        ),
                      defaultCurrency
                    )}
                </strong>
                <span>
                  {formatCurrency(
                    totalsContext.mainCryptoWallets!.totalUsd,
                    Currency.USD
                  )}
                </span>
              </div>
            </div>
            <div className="accounting-balances-users-total-balances">
              <IconUsersTotalBalances />
              <span className="accounting-balances-title">
                {t("accounting.balances.users-total")}
              </span>
              <div>
                {typeof totalBalances.defaultBalance === "number" && (
                  <strong>
                    {formatCurrency(
                      totalBalances.defaultBalance,
                      defaultCurrency
                    )}
                  </strong>
                )}
                {typeof totalBalances.usdBalance === "number" && (
                  <span>
                    {formatCurrency(totalBalances.usdBalance, Currency.USD)}
                  </span>
                )}
              </div>
            </div>
            <div className="accounting-balances-hold-as-bets">
              <IconGgr />
              <span className="accounting-balances-title">
                {t("accounting.balances.hold-as-bets")}
              </span>
              <div>
                <strong>
                  {holdBetsTotal &&
                    formatCurrency(
                      Number(
                        holdBetsTotal[
                          getFildNameWithCurrency(
                            "amount",
                            defaultCurrency
                          ) as keyof ISportBetHoldsProjection
                        ]
                      ),
                      defaultCurrency
                    )}
                </strong>
                <span>
                  {holdBetsTotal &&
                    formatCurrency(
                      parseFloat(holdBetsTotal.amount_usd),
                      Currency.USD
                    )}
                </span>
              </div>
            </div>
          </div>
          <div className="accounting-balances-container">
            <div className="accounting-balances-table">
              <div className="accounting-balances-table-header">
                <div>{t("accounting.balances.currency")}</div>
                <div>{t("accounting.balances.main-wallet-hold")}</div>
                <div>{t("accounting.balances.users-hold")}</div>
              </div>

              {cryptoCurrencies.map((currency) => (
                <div key={currency} className="accounting-balances-table-row">
                  <div className="accounting-balances-table-crypto">
                    <CurrencyIcon currency={currency} />
                    <span>
                      <strong>{currency}</strong>
                      {t(`common.${currency.toLowerCase()}`)}
                    </span>
                  </div>

                  <div className="accounting-balances-table-col">
                    <div>
                      <strong>
                        {formatCurrency(
                          totalsContext.mainCryptoWallets?.totals[currency]
                            ?.balance ?? 0,
                          currency
                        )}
                      </strong>
                      <span>
                        {latestExchangeRatesData &&
                          formatCurrency(
                            parseFloat(
                              totalsContext.mainCryptoWallets?.totals[currency]
                                ?.balance_usd ?? "0"
                            ) *
                              parseFloat(
                                latestExchangeRatesData[defaultCurrency].rate
                              ),
                            defaultCurrency
                          )}
                      </span>
                      <span>
                        {formatCurrency(
                          totalsContext.mainCryptoWallets?.totals[currency]
                            ?.balance_usd ?? 0,
                          Currency.USD
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="accounting-balances-table-col">
                    <div>
                      <strong>
                        {formatCurrency(
                          totalsContext.userCryptoWallets!.totals[currency]
                            ?.total_balance ?? 0,
                          currency
                        )}
                      </strong>
                      <span>
                        {latestExchangeRatesData &&
                          formatCurrency(
                            parseFloat(
                              totalsContext.userCryptoWallets!.totals[currency]
                                ?.total_balances_usd ?? "0"
                            ) *
                              parseFloat(
                                latestExchangeRatesData[defaultCurrency].rate
                              ),
                            defaultCurrency
                          )}
                      </span>
                      <span>
                        {formatCurrency(
                          totalsContext.userCryptoWallets!.totals[currency]
                            ?.total_balances_usd ?? 0,
                          Currency.USD
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="accounting-balances-hold-as-bets-table">
              <div className="accounting-balances-table-header">
                <div>{t("accounting.balances.hold-as-bets")}</div>
              </div>

              {cryptoCurrencies.map((currency) => {
                const holdBetsByCurrency = holdBets.find(
                  (b) => b.currency === currency
                );

                return (
                  <div key={currency} className="accounting-balances-table-row">
                    <div className="accounting-balances-table-col">
                      <div>
                        <strong>
                          {holdBetsByCurrency
                            ? formatCurrency(
                                holdBetsByCurrency.amount,
                                currency
                              )
                            : "--"}
                        </strong>
                        <span>
                          {holdBetsByCurrency
                            ? formatCurrency(
                                holdBetsByCurrency[
                                  getFildNameWithCurrency(
                                    "amount",
                                    defaultCurrency
                                  ) as keyof ISportBetHoldsProjection
                                ],
                                defaultCurrency
                              )
                            : "--"}
                        </span>
                        <span>
                          {holdBetsByCurrency
                            ? formatCurrency(
                                holdBetsByCurrency.amount_usd,
                                Currency.USD
                              )
                            : "--"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Spin>
  );

  async function load() {
    try {
      setLoading(true);
      const [latestExchangeRatesResponse, holdBetsResponse] = await Promise.all(
        [
          loadLatestExchangeRates(adminBFFClient, Currency.USD, [
            defaultCurrency,
            ...cryptoCurrencies,
          ]),
          adminBFFClient.querySportBetsHolds(),
        ]
      );

      const latestExchangeRatesResult: { [key: string]: IExchangeRatePair } =
        {};
      latestExchangeRatesResponse.rates.forEach((rate) => {
        latestExchangeRatesResult[rate.toCurrency] = rate;
      });
      setLatestExchangeRatesData(latestExchangeRatesResult);
      setHoldBets(holdBetsResponse.items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
};

async function loadLatestExchangeRates(
  client: AdminBFFClient,
  fromCurrency: Currency,
  toCurrencies: Currency[]
) {
  return await client.getLatestExchangeRates({
    fromCurrency,
    toCurrencies,
  });
}

export default AccountingBalances;
