import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import classnames from "classnames";
import moment from "moment-timezone";
import { Currency, PaymentProvider, Timezone } from "@finbackoffice/enums";
import { Form, Select, Spin } from "antd";
import { IPaymentsCurrencyConfigWithCurrency } from "@/providers";
import { IPaymentsAccounting } from "@finbackoffice/adminbff-client/dist/interface";
import {
  CurrencyIcon,
  CurrencyFormatterContext,
  PaymentsConfigContext,
  AdminClientContext,
  TotalsContext,
  useCurrencyOptions,
  useAppConfig,
  useUserTags,
} from "@finbackoffice/backoffice-core-next";
import { useTranslation } from "react-i18next";
import {
  AdminBFFClient,
  IExchangeRatePair,
} from "@finbackoffice/adminbff-client";
import "./accounting-transactions.sass";

const { Option } = Select;

type ILatestExchangeRatesData = { [key: string]: IExchangeRatePair } | null;
type ITransactionsDataState = {
  loading: boolean;
  data: {
    [key: string]: {
      [key: string]: IPaymentsAccounting;
    };
  };
};

const AccountingTransactions: FC<{ selectedCurrency?: Currency }> = ({
  selectedCurrency,
}): ReactElement => {
  const { t } = useTranslation();
  const defaultTimezone = useAppConfig(
    "DEFAULT_TIMEZONE"
  ) as keyof typeof Timezone;
  const adminBFFClient = useContext(AdminClientContext);
  const { formatCurrency } = useContext(CurrencyFormatterContext);
  const totalsContext = useContext(TotalsContext);
  const { getConfigs } = useContext(PaymentsConfigContext);
  const { defaultCurrency } = useCurrencyOptions();
  const { changeableUserTags } = useUserTags();
  const [timezone, setTimezone] = useState(Timezone[defaultTimezone]);
  const [filter, setFilter] = useState<{ tags: string[] }>({ tags: [] });
  const [latestExchangeRatesData, setLatestExchangeRatesData] =
    useState<ILatestExchangeRatesData>(null);
  const [transactionDepositData, setTransactionDepositData] =
    useState<ITransactionsDataState>({
      loading: false,
      data: {},
    });
  const [transactionWithdrawalData, setTransactionWithdrawalData] =
    useState<ITransactionsDataState>({
      loading: false,
      data: {},
    });
  const cryptoCurrencies = getConfigs()
    .filter((config) =>
      selectedCurrency
        ? config.enabled && config.currency === selectedCurrency
        : config.enabled
    )
    .sort(
      (
        a: IPaymentsCurrencyConfigWithCurrency,
        b: IPaymentsCurrencyConfigWithCurrency
      ) => (a.confirmation_count || 1) - (b.confirmation_count || 1)
    )
    .map((config) => config.currency as Currency);

  const handleChangeTimezone = useCallback((timezone: string) => {
    setTimezone(timezone as Timezone);
  }, []);

  const loadTransactionsDepositData = useCallback(
    async (timeline: string, from: string, to: string) => {
      setTransactionDepositData((state) => ({
        ...state,
        loading: true,
      }));
      try {
        const response = await adminBFFClient.getDepositAccounting({
          from,
          to,
          tags: filter.tags,
          providers: [
            PaymentProvider.Correction,
            PaymentProvider.CryptoAPI,
            PaymentProvider.BankTransfer,
            PaymentProvider.PixAPI,
            PaymentProvider.TolaAPI,
          ],
        });

        let dataToSave: {
          [key: string]: {
            [key: string]: IPaymentsAccounting;
          };
        } = transactionDepositData.data;

        if (!response.items.length) {
          for (const currency in dataToSave) {
            dataToSave[currency][timeline] = {} as IPaymentsAccounting;
          }
        }

        response.items.forEach((item) => {
          !dataToSave[item.currency.toLowerCase()] &&
            (dataToSave[item.currency.toLowerCase()] = {});
          dataToSave[item.currency.toLowerCase()][timeline] = item;
        });

        setTransactionDepositData((state) => ({
          data: dataToSave,
          loading: false,
        }));
      } catch (e) {
        console.error(e);
        setTransactionDepositData((state) => ({
          ...state,
          loading: false,
        }));
      }
    },
    [adminBFFClient, filter, transactionDepositData.data]
  );

  const loadTransactionsWithdrawalData = useCallback(
    async (timeline: string, from: string, to: string) => {
      setTransactionWithdrawalData((state) => ({
        ...state,
        loading: true,
      }));
      try {
        const response = await adminBFFClient.getWithdrawalAccounting({
          from,
          to,
          tags: filter.tags,
          providers: [
            PaymentProvider.Correction,
            PaymentProvider.CryptoAPI,
            PaymentProvider.BankTransfer,
            PaymentProvider.PixAPI,
            PaymentProvider.TolaAPI,
          ],
        });

        let dataToSave: {
          [key: string]: {
            [key: string]: IPaymentsAccounting;
          };
        } = transactionWithdrawalData.data;

        if (!response.items.length) {
          for (const currency in dataToSave) {
            dataToSave[currency][timeline] = {} as IPaymentsAccounting;
          }
        }

        response.items.forEach((item) => {
          !dataToSave[item.currency.toLowerCase()] &&
            (dataToSave[item.currency.toLowerCase()] = {});
          dataToSave[item.currency.toLowerCase()][timeline] = item;
        });

        setTransactionWithdrawalData((state) => ({
          data: dataToSave,
          loading: false,
        }));
      } catch (e) {
        console.error(e);
        setTransactionWithdrawalData((state) => ({
          ...state,
          loading: false,
        }));
      }
    },
    [adminBFFClient, filter, transactionWithdrawalData.data]
  );

  const loadExchangeRates = useCallback(async () => {
    try {
      const latestExchangeRatesResponse = await loadLatestExchangeRates(
        adminBFFClient,
        Currency.USD,
        [defaultCurrency, ...cryptoCurrencies]
      );

      const latestExchangeRatesResult: { [key: string]: IExchangeRatePair } =
        {};
      latestExchangeRatesResponse.rates.forEach((rate) => {
        latestExchangeRatesResult[rate.toCurrency] = rate;
      });

      setLatestExchangeRatesData(latestExchangeRatesResult);
    } catch (error) {
      console.error(error);
    }
  }, [adminBFFClient, cryptoCurrencies, defaultCurrency]);

  useEffect(() => {
    // Deposit
    loadTransactionsDepositData(
      "today",
      moment().tz(timezone).startOf("day").toISOString(),
      moment().tz(timezone).endOf("day").toISOString()
    );
    loadTransactionsDepositData(
      "yesterday",
      moment().tz(timezone).add(-1, "day").startOf("day").toISOString(),
      moment().tz(timezone).add(-1, "day").endOf("day").toISOString()
    );
    loadTransactionsDepositData(
      "m2d",
      moment().tz(timezone).startOf("month").toISOString(),
      moment().tz(timezone).endOf("month").toISOString()
    );
    loadTransactionsDepositData(
      "prevm2d",
      moment().tz(timezone).add(-1, "month").startOf("month").toISOString(),
      moment().tz(timezone).add(-1, "month").endOf("month").toISOString()
    );

    // Withdrawal
    loadTransactionsWithdrawalData(
      "today",
      moment().tz(timezone).startOf("day").toISOString(),
      moment().tz(timezone).endOf("day").toISOString()
    );
    loadTransactionsWithdrawalData(
      "yesterday",
      moment().tz(timezone).add(-1, "day").startOf("day").toISOString(),
      moment().tz(timezone).add(-1, "day").endOf("day").toISOString()
    );
    loadTransactionsWithdrawalData(
      "m2d",
      moment().tz(timezone).startOf("month").toISOString(),
      moment().tz(timezone).endOf("month").toISOString()
    );
    loadTransactionsWithdrawalData(
      "prevm2d",
      moment().tz(timezone).add(-1, "month").startOf("month").toISOString(),
      moment().tz(timezone).add(-1, "month").endOf("month").toISOString()
    );
    loadExchangeRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminBFFClient, timezone, filter]);

  useEffect(() => {
    totalsContext.subscribe();
    return () => {
      totalsContext.unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        className={classnames({
          "accounting-transactions-filter": !selectedCurrency,
        })}
      >
        <Form.Item
          label={`${t("common.timezone")}:`}
          className="accounting-transactions-timezone"
        >
          <Select
            value={timezone}
            onChange={handleChangeTimezone}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch={true}
          >
            {Object.keys(Timezone).map((c) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!selectedCurrency && <i className="vertical-separator" />}
        <Form.Item className="accounting-transactions-timezone">
          <Select
            onChange={(value: string) =>
              setFilter?.({ ...filter, tags: value ? [value] : [] })
            }
            removeIcon
            style={{ minWidth: 120, width: "auto" }}
            defaultValue=""
          >
            <Option value="">{t("players.filter.custom-tags")}</Option>
            {changeableUserTags.map((tag) => (
              <Option value={tag.tag} key={tag.tag}>
                {tag.tag}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="accounting-transactions">
        {cryptoCurrencies.map((c) => {
          const currency = c.toLowerCase();
          return (
            <Spin
              key={c}
              spinning={
                transactionDepositData.loading ||
                transactionWithdrawalData.loading
              }
            >
              <div
                className={`accounting-transaction-item-${currency}`}
                key={c}
              >
                <div>
                  <CurrencyIcon currency={currency.toUpperCase() as Currency} />
                  <div className="accounting-transaction-coin">
                    <strong>
                      {totalsContext.mainCryptoWallets?.totals.hasOwnProperty(
                        currency.toUpperCase()
                      ) ? (
                        formatCurrency(
                          totalsContext.mainCryptoWallets?.totals[
                            currency.toUpperCase()
                          ]?.balance || "0",
                          currency.toUpperCase() as Currency,
                          true,
                          false,
                          "start",
                          true
                        )
                      ) : (
                        <span>{currency.toUpperCase()}</span>
                      )}
                    </strong>
                    <span>{t(`common.${currency}`)}</span>
                  </div>
                  {totalsContext.mainCryptoWallets?.totals.hasOwnProperty(
                    currency.toUpperCase()
                  ) ? (
                    <div className="accounting-transaction-amount">
                      <strong>
                        {latestExchangeRatesData &&
                          formatCurrency(
                            parseFloat(
                              totalsContext.mainCryptoWallets?.totals[
                                currency.toUpperCase()
                              ]?.balance_usd || "0"
                            ) *
                              parseFloat(
                                latestExchangeRatesData[defaultCurrency].rate
                              ),
                            defaultCurrency
                          )}
                      </strong>
                      <span>
                        USDT{" "}
                        {formatCurrency(
                          totalsContext.mainCryptoWallets?.totals[
                            currency.toUpperCase()
                          ]?.balance_usd || "0",
                          Currency.USD,
                          false
                        )}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="accounting-transaction-table">
                  <div className="accounting-transaction-table-header">
                    <div>Deposit</div>
                    <div>Withdrawal</div>
                  </div>
                  <div className="accounting-transaction-table-row">
                    <label>TODAY</label>
                    <div>
                      {formatCurrency(
                        transactionDepositData.data[currency]?.today?.amount ||
                          0,
                        transactionDepositData.data[currency]?.today?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionDepositData.data[currency]?.today?.count ||
                          0}
                      </strong>
                    </div>
                    <div>
                      {formatCurrency(
                        transactionWithdrawalData.data[currency]?.today
                          ?.amount || 0,
                        transactionWithdrawalData.data[currency]?.today
                          ?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionWithdrawalData.data[currency]?.today
                          ?.count || 0}
                      </strong>
                    </div>
                  </div>
                  <div className="accounting-transaction-table-row">
                    <label>YESTERDAY</label>
                    <div>
                      {formatCurrency(
                        transactionDepositData.data[currency]?.yesterday
                          ?.amount || 0,
                        transactionDepositData.data[currency]?.yesterday
                          ?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionDepositData.data[currency]?.yesterday
                          ?.count || 0}
                      </strong>
                    </div>
                    <div>
                      {formatCurrency(
                        transactionWithdrawalData.data[currency]?.yesterday
                          ?.amount || 0,
                        transactionWithdrawalData.data[currency]?.yesterday
                          ?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionWithdrawalData.data[currency]?.yesterday
                          ?.count || 0}
                      </strong>
                    </div>
                  </div>
                  <div className="accounting-transaction-table-row">
                    <label>M2D</label>
                    <div>
                      {formatCurrency(
                        transactionDepositData.data[currency]?.m2d?.amount,
                        transactionDepositData.data[currency]?.m2d?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionDepositData.data[currency]?.m2d?.count || 0}
                      </strong>
                    </div>
                    <div>
                      {formatCurrency(
                        transactionWithdrawalData.data[currency]?.m2d?.amount,
                        transactionWithdrawalData.data[currency]?.m2d?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionWithdrawalData.data[currency]?.m2d?.count ||
                          0}
                      </strong>
                    </div>
                  </div>
                  <div className="accounting-transaction-table-row">
                    <label>
                      Prev. Month:{" "}
                      {moment()
                        .tz(Timezone["Asia/Seoul"])
                        .add(-1, "month")
                        ?.format("MMMM")}
                    </label>
                    <div>
                      {formatCurrency(
                        transactionDepositData.data[currency]?.prevm2d?.amount,
                        transactionDepositData.data[currency]?.prevm2d?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionDepositData.data[currency]?.prevm2d
                          ?.count || 0}
                      </strong>
                    </div>
                    <div>
                      {formatCurrency(
                        transactionWithdrawalData.data[currency]?.prevm2d
                          ?.amount,
                        transactionWithdrawalData.data[currency]?.prevm2d
                          ?.currency
                      )}
                      <strong>
                        Req.:{" "}
                        {transactionWithdrawalData.data[currency]?.prevm2d
                          ?.count || 0}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          );
        })}
      </div>
    </div>
  );
};

async function loadLatestExchangeRates(
  client: AdminBFFClient,
  fromCurrency: Currency,
  toCurrencies: Currency[]
) {
  return await client.getLatestExchangeRates({
    fromCurrency,
    toCurrencies,
  });
}

export default AccountingTransactions;
