"use client";

import React, {
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  IconUser,
  ASSETS_PATH,
  useAppConfig,
  useLogin,
  ProfileInfoContext,
  usePermissions,
  RouteNames,
  DEFAULT_ROUTE,
} from "@finbackoffice/backoffice-core-next";
import { Button, Layout, Select } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Sidebar } from "./Sidebar";
import { Breadcrumbs } from "@/components/breadcrumbs";
import { HeaderSearch } from "@/components/header-search/HeaderSearch";
import { HeaderServerTime } from "@/components/server-time/HeaderServerTime";
import { redirect, usePathname, useRouter } from "next/navigation";
import Image from "next/image";
import "moment/locale/ko";
import "moment/locale/pt";
import moment from "moment";
import "./layout.sass";

const { Header, Content } = Layout;
const { Option } = Select;

type IPageLayoutProps = {
  children: ReactNode;
};

export const PageLayout = ({ children }: IPageLayoutProps) => {
  const { profile } = useContext(ProfileInfoContext);
  const appName = useAppConfig("APP_NAME");
  const locale = useAppConfig("DEFAULT_LOCALE");
  const { onLogout } = useLogin();
  const pathname = usePathname();
  const router = useRouter();
  const { i18n } = useTranslation();
  const { isAvailableMenu } = usePermissions();
  const [language, setLanguage] = useState<string>(i18n.language);
  const [, _lang, ...pageRoutes] = pathname!.split("/");
  const menuItem = pageRoutes?.[0];
  const subMenuItem = pageRoutes?.[1];

  useEffect(() => {
    moment.locale(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      profile &&
      !isAvailableMenu(
        menuItem as RouteNames,
        subMenuItem as RouteNames | undefined
      )
    ) {
      redirect(DEFAULT_ROUTE);
    }
  }, [isAvailableMenu, menuItem, subMenuItem, profile]);

  const languageOptions = useMemo(() => {
    return ["en", locale];
  }, [locale]);

  const handleLanguageChange = useCallback(
    (value: string) => {
      moment.locale(value);
      setLanguage(value);
      const [, lang, ...rest] = pathname?.split("/") ?? [];
      const updatedPathname = ["", value, ...rest].join("/");
      router.push(updatedPathname);
    },
    [i18n, pathname]
  );

  return (
    <Layout>
      <Sidebar brand={appName} />
      <Suspense fallback={null}>
        <Layout className="page-main-layout" data-testid="page-layout">
          <Header className="page-header">
            <div>
              <Breadcrumbs />
              <div className="page-header-middle-content">
                <HeaderServerTime />
                <HeaderSearch />
              </div>
              <div className="header-user">
                <IconUser />
                <span>{profile?.email}</span>
                <Select value={language} onChange={handleLanguageChange}>
                  {languageOptions.map((lang) => (
                    <Option
                      value={lang}
                      key={lang}
                      className="header-languages-list-item"
                    >
                      <Image
                        alt="english"
                        src={`${ASSETS_PATH}/assets/common/languages/${lang}.svg`}
                        width={16}
                        height={13}
                      />
                      {lang.toUpperCase()}
                    </Option>
                  ))}
                </Select>
                <LogoutButton onClick={onLogout} />
              </div>
            </div>
          </Header>
          <Content className="page-content">{children}</Content>
        </Layout>
      </Suspense>
    </Layout>
  );
};

const LogoutButton = (props: { onClick: () => void }) => {
  return (
    <Button
      type="primary"
      onClick={props.onClick}
      icon={<LogoutOutlined />}
      data-testid="logout-button"
    >
      Logout
    </Button>
  );
};
