import * as Sentry from "@sentry/react";

export function initSentry(environment: string, dsn: string, nodeEnv: string) {
  Sentry.init({
    dsn,
    environment,
    enabled: nodeEnv === "production",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0,
  });
}
