import { Breadcrumb } from "antd";
import { useContext } from "react";
import {
  BreadcrumbsContext,
  ICrumb,
} from "@finbackoffice/backoffice-core-next";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import "./breadcrumbs.sass";

export const Breadcrumbs = () => {
  const [t, i18n] = useTranslation();
  const { getCrumbs } = useContext(BreadcrumbsContext);

  const crumbs: ICrumb[] = getCrumbs();

  const breadcrumbItems = crumbs.map((c) => ({
    key: c.pathKey,
    title: <Link href={`/${i18n.language}${c.route}`}>{t(`sidebar.${c.pathKey}`)}</Link>,
  }));

  return <Breadcrumb className="page-breadcrumb" items={breadcrumbItems} />;
};
