"use client";

import { Card, Tabs, TabsProps } from "antd";
import React, { FC, ReactElement } from "react";
import {
  RouteNames,
  useRouterWithLocale,
} from "@finbackoffice/backoffice-core-next";
import AccountingBalances from "./balances/AccountingBalances";
import AccountingTransactions from "./transactions/AccountingTransactions";
import { useParams } from "next/navigation";
import "./accounting.sass";

export const enum AccountingTabTypes {
  BALANCES = "balances",
  TRANSACTIONS = "transactions",
}

const Accounting: FC = (): ReactElement => {
  const { routerWithLocale } = useRouterWithLocale();
  const params = useParams();

  const tabItems: TabsProps["items"] = [
    {
      key: AccountingTabTypes.BALANCES,
      label: "Balances",
      children: <AccountingBalances />,
    },
    {
      key: AccountingTabTypes.TRANSACTIONS,
      label: "Deposit / Withdrawal",
      children: <AccountingTransactions />,
    },
  ];

  return (
    <section className="accounting-container">
      <Card>
        <Tabs
          type="card"
          className="accounting tabs-container"
          defaultActiveKey={params?.tabType as string}
          onChange={(key) => {
            routerWithLocale(
              `${RouteNames.DEFINY}/${RouteNames.ACCOUNTING}/${key}`
            );
          }}
          items={tabItems}
        />
      </Card>
    </section>
  );
};

export default Accounting;
