import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { Form, Input, Button, Spin } from "antd";
import { ITokenResponse } from "@finbackoffice/adminbff-client";
import { ITfaFormValues } from "@finbackoffice/backoffice-core-next";

type ILoginForm = {
  onSubmit: (values: ITfaFormValues) => void;
  token: ITokenResponse;
  onChanges: () => void;
  close: () => void;
  spinning: boolean;
};

export const LoginFormTfa: FC<ILoginForm> = ({
  onSubmit,
  token,
  onChanges,
  close,
  spinning,
}) => {
  const { t } = useTranslation();
  const validUntil = new Date(token?.valid_until.toString()).getTime();

  const onFinish = ({ code }: ITfaFormValues) => {
    onSubmit({ id: token.id, code });
  };

  const onError = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const renderContent = useCallback(
    ({ minutes, seconds, completed }: any) => {
      if (completed) {
        return null;
      }

      return (
        <p className="login-countdown">
          {t("login.login-form-tfa.resend.text", { minutes, seconds })}
        </p>
      );
    },
    [t]
  );

  return (
    <Form
      name="basic"
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onError}
      autoComplete="on"
      onChange={onChanges}
    >
      <Spin spinning={spinning}>
        <p className="login-title">Аccount is protected with 2FA.</p>
        <p className="login-hint">
          {t(`login.login-form-tfa.channel.${token.channel}`, {
            data: token.hint,
          })}
        </p>
        <div className="form-content">
          <Form.Item
            name="code"
            className="login-code"
            rules={[
              {
                required: true,
                message: t("login.login-form.code.error.required"),
              },
            ]}
          >
            <Input
              type="number"
              placeholder={t("login.login-form.code.label")}
              data-testid="code"
            />
          </Form.Item>
          <Countdown
            date={validUntil}
            renderer={renderContent}
            onComplete={() => close()}
          />
        </div>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="submit"
          className="login-btn"
        >
          {t("login.login-form.button.text")}
        </Button>
      </Spin>
    </Form>
  );
};
