"use client";

import React, { useCallback } from "react";
import { Layout } from "antd";
import { LoginForm } from "./LoginForm";
import {
  useLogin,
  useAppConfig,
  ASSETS_PATH,
} from "@finbackoffice/backoffice-core-next";
import { ReactSVG } from "react-svg";
import { LoginFormTfa } from "@/screens/login/LoginFormTfa";
import "./login.sass";

const { Content, Footer } = Layout;

// NOTE: only AdminClientContext is accessible from Login page
export const LoginPage = () => {
  const {
    onSubmit,
    onTfaSubmit,
    isLoading,
    setErrorMessage,
    twoFactorToken,
    setTwoFactorToken,
    errorMessage,
  } = useLogin();

  const resetError = useCallback(() => {
    !!errorMessage && setErrorMessage(null);
  }, [errorMessage, setErrorMessage]);

  const appName = useAppConfig("APP_NAME").toLowerCase();

  return (
    <Layout className="login" data-testid="page-login">
      <ReactSVG
        className="login-logo"
        src={`${ASSETS_PATH}/assets/${appName}/common/logo-bo.svg`}
      />
      <Content>
        {twoFactorToken ? (
          <LoginFormTfa
            token={twoFactorToken}
            onSubmit={onTfaSubmit}
            onChanges={resetError}
            spinning={isLoading}
            close={() => setTwoFactorToken(null)}
          />
        ) : (
          <LoginForm
            onSubmit={onSubmit}
            spinning={isLoading}
            onChanges={resetError}
          />
        )}
        {errorMessage && (
          <div className="login-error-message">{errorMessage}</div>
        )}
      </Content>
      <Footer>
        @{new Date().getFullYear()} {appName}
      </Footer>
    </Layout>
  );
};
