"use client";

import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import {
  AdminClientProvider,
  AntdConfigProvider,
  AppConfigProvider,
  CookieProvider,
  I18nextProvider,
  IApplicationConfig,
  TokenProvider,
} from "@finbackoffice/backoffice-core-next";
import { initSentry } from "@/providers/initSentry";
import { ConfigProvider } from "antd";
import { captureException } from "@sentry/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

type IProvidersProps = {
  token: string;
  appConfig: IApplicationConfig;
  translations: Record<string, any>;
  headers: Record<string, string>;
  lang: string;
};

const ANT_THEME = {
  token: {
    colorTextBase: "#17293E",
    fontFamily: "Arial",
    fontSize: 12,
    lineHeight: 1,
    borderRadius: 2,
    colorBorder: "#CBD4DA",
    colorPrimary: "#077cb3",
    colorError: "#eb443c",
  },
  components: {
    Button: {
      defaultColor: "#4e5e70",
      defaultBorderColor: "#8d95a0",
      defaultGhostBorderColor: "#8d95a0",
      defaultGhostColor: "4e5e70",
      ghostBg: "#fff",
      colorPrimary: "#1599D6",
      colorPrimaryHover: "#45C3FF",
      colorPrimaryActive: "#064B6B",
      borderColorDisabled: "#e2e7e9",
      colorBgContainerDisabled: "#F6F6F7",
      colorError: "#eb443c",
      colorErrorActive: "#D73033",
      colorErrorHover: "#FC6159",
      fontSize: 12,
      controlHeight: 22,
      controlHeightLG: 22,
      controlHeightSM: 22,
    },
    Switch: {
      trackHeight: 14,
      trackHeightSM: 14,
      trackMinWidth: 28,
      trackMinWidthSM: 28,
      handleSize: 10,
      handleSizeSM: 10,
      colorPrimary: "#e85454",
      colorPrimaryBorder: "#f8c7ca",
      colorPrimaryHover: "rgba(232, 84, 84, .8)",
    },
    Select: {
      controlHeight: 22,
      controlHeightSM: 22,
      controlHeightXS: 22,
      fontSize: 12,
      multipleItemHeight: 22,
      multipleItemHeightLG: 22,
      optionSelectedBg: "#f5f5f5",
      optionSelectedFontWeight: 400,
      paddingContentVertical: 3,
    },

    Input: {
      fontSize: 12,
      controlHeight: 22,
      controlHeightLG: 22,
      controlHeightSM: 22,
      controlHeightXS: 22,
      paddingInline: 8,
      paddingBlock: 3,
      borderRadius: 2,
    },
    InputNumber: {
      fontSize: 12,
      controlHeight: 22,
      controlHeightSM: 22,
      controlHeightLG: 22,
      controlPaddingHorizontal: 8,
      paddingBlock: 3,
      borderRadius: 2,
      paddingSM: 8,
    },
    Form: {
      controlHeight: 22,
      controlHeightSM: 22,
      controlHeightXS: 22,
      labelHeight: 22,
      borderRadius: 2,
      fontSize: 12,
    },
    Pagination: {
      itemSize: 22,
    },
    Table: {
      borderRadius: 0,
    },
    Radio: {
      dotSize: 7,
      colorPrimary: "#077cb3",
      padding: 6,
      paddingXS: 6,
    },
    Modal: {
      padding: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
      margin: 0,
      marginLG: 0,
      marginSM: 0,
      paddingContentHorizontalLG: 0,
    },
    Checkbox: {
      controlInteractiveSize: 12,
      fontSize: 12,
      fontSizeLG: 12,
    },
  },
};

export const RootProviders: FC<PropsWithChildren<IProvidersProps>> = ({
  token,
  appConfig,
  translations,
  lang,
  headers,
  children,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    dayjs.extend(utc);
    setIsReady(true);
    initSentry(
      appConfig.CI_ENVIRONMENT_NAME,
      appConfig.SENTRY_DSN,
      process.env.NODE_ENV
    );
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <AntdConfigProvider>
      <AppConfigProvider config={appConfig}>
        <TokenProvider token={token}>
          <CookieProvider>
            <I18nextProvider translations={translations} lang={lang}>
              <ConfigProvider theme={ANT_THEME}>
                <AdminClientProvider
                  clientName={"desktop-backoffice"}
                  onUnhandledError={captureException}
                  headers={headers}
                >
                  {children}
                </AdminClientProvider>
              </ConfigProvider>
            </I18nextProvider>
          </CookieProvider>
        </TokenProvider>
      </AppConfigProvider>
    </AntdConfigProvider>
  );
};
