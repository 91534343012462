function factorial(value: number): number {
  let result = 1;

  for (let i: number = 1; i <= value; ++i) {
    result *= i;
  }

  return result;
}

function systemBetVariantsCount(
  betsAmount: number,
  systemBetSelection: number
) {
  if (betsAmount < 3) {
    return 0;
  }

  return (
    factorial(betsAmount) /
    (factorial(systemBetSelection) * factorial(betsAmount - systemBetSelection))
  );
}

export default systemBetVariantsCount;
