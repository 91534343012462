import { FC, SyntheticEvent, useCallback, useContext, useState } from "react";
import { Spin, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IUserSummaryProjection } from "@finbackoffice/adminbff-client";
import {
  CurrencyFormatterContext,
  AdminClientContext,
  useCurrencyOptions,
} from "@finbackoffice/backoffice-core-next";
import { Currency } from "@finbackoffice/enums";
import {
  ComparisonFilterExpression,
  FilterKind,
} from "@finbackoffice/http-client";
import moment from "moment";
import { useTranslation } from "react-i18next";

type IUserInfoProps = {
  user_id: number;
  currency: Currency;
};

const NOT_FOUND = "N/A";

export const UserInfoTooltip: FC<IUserInfoProps> = ({ user_id, currency }) => {
  const { t } = useTranslation();
  const adminBFFClient = useContext(AdminClientContext);
  const { formatCurrency } = useContext(CurrencyFormatterContext);
  const { defaultCurrency } = useCurrencyOptions();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<
    IUserSummaryProjection | undefined
  >();

  const loadUser = useCallback(
    async (open: boolean) => {
      if (!open || (open && userInfo)) {
        return;
      }
      try {
        setLoading(true);
        const response = await adminBFFClient.queryUserSummary(
          defaultCurrency,
          {
            page: 1,
            items_per_page: 1,
            filter: [
              {
                kind: FilterKind.NumericFilter,
                field: "user_id",
                value: Number(user_id),
                expression: ComparisonFilterExpression.Equal,
              },
            ],
          }
        );
        setUserInfo(response.items[0]);
      } finally {
        setLoading(false);
      }
    },
    [user_id, userInfo, adminBFFClient]
  );

  const handleTooltipClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <Tooltip
      placement={"right"}
      onOpenChange={loadUser}
      overlayClassName="info-tooltip"
      title={
        <div className="bets-user-tooltip" onClick={handleTooltipClick}>
          <>
            {loading && <Spin spinning={true} />}
            {!loading && !userInfo && t("bets.user_info.not_found")}
            {!loading && userInfo && (
              <ul>
                <li>
                  {t("bets.user_info.reg_date")}:
                  <span>
                    {moment(userInfo.created_at).format("MM/DD/YY HH:mm") ||
                      NOT_FOUND}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.name")}:
                  <span>
                    {!userInfo.last_name && !userInfo.first_name
                      ? NOT_FOUND
                      : `${userInfo.last_name || NOT_FOUND} ${
                          userInfo.first_name || NOT_FOUND
                        }`}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.status")}:
                  <span>
                    {userInfo.tags.length
                      ? userInfo.tags.join(", ")
                      : NOT_FOUND}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.referred_by")}:
                  <span>{userInfo.referral || NOT_FOUND}</span>
                </li>
                <li>
                  {t("bets.user_info.balance")}:
                  <span>
                    {formatCurrency(userInfo.balance, currency) || NOT_FOUND}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.m_profit")}:
                  <span>
                    {formatCurrency(userInfo.month_profit, currency) ||
                      NOT_FOUND}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.t_profit")}:
                  <span>
                    {formatCurrency(userInfo.total_profit, currency) ||
                      NOT_FOUND}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.domain")}:
                  <span>
                    {userInfo.last_login_origin?.replace(/^https?:\/\//, "") ||
                      NOT_FOUND}
                  </span>
                </li>
                <li>
                  {t("bets.user_info.notes")}:
                  <span>
                    {userInfo.notes.length
                      ? userInfo.notes.join(", ")
                      : NOT_FOUND}
                  </span>
                </li>
              </ul>
            )}
          </>
        </div>
      }
    >
      <InfoCircleOutlined onClick={(e) => e.stopPropagation()} />
    </Tooltip>
  );
};
