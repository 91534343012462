import { ReloadOutlined } from "@ant-design/icons";
import {
  CurrencySelect,
  DepositApprovedCount,
  IPlayersFilter,
  useUserTags,
} from "@finbackoffice/backoffice-core-next";
import { Currency, UserStatus } from "@finbackoffice/enums";
import { Button, DatePicker, Form, Input, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import { FC, SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

type IPlayersFiltersProps = {
  onChange: (filter: Partial<IPlayersFilter>) => void;
  onBaseCurrencyChange: (base_currency: Currency) => void;
  accountStatus?: UserStatus;
  baseCurrency?: Currency;
  registrationDateFrom?: string;
  registrationDateTo?: string;
  filter: IPlayersFilter;
};

const DEPOSITS_APPROVED_OPTIONS = [
  {
    label: "players-activity.deposits-approved.all",
    value: DepositApprovedCount.ALL,
  },
  {
    label: "players-activity.deposits-approved.first-deposit",
    value: DepositApprovedCount["1st"],
  },
  {
    label: "players-activity.deposits-approved.second-deposit",
    value: DepositApprovedCount["2nd"],
  },
  {
    label: "players-activity.deposits-approved.third-deposit",
    value: DepositApprovedCount["3rd"],
  },
  {
    label: "players-activity.deposits-approved.any-deposit",
    value: DepositApprovedCount.ANY,
  },
  {
    label: "players-activity.deposits-approved.no-deposit",
    value: DepositApprovedCount.NO,
  },
];

export const PlayersFilters: FC<IPlayersFiltersProps> = ({
  onChange,
  onBaseCurrencyChange,
  accountStatus,
  baseCurrency,
  registrationDateFrom,
  registrationDateTo,
  filter,
}) => {
  const [t] = useTranslation();
  const { protectedUserTags, changeableUserTags } = useUserTags();
  const [selectedProtectedTag, setSelectedProtectedTag] = useState<string>("");
  const [selectedChangeableTag, setSelectedChangeableTag] =
    useState<string>("");
  const tooltipText = `${t("dashboard.table.column.username")}, ${t(
    "user.first_name"
  )}, ${t("user.last_name")}, ${t(
    "settings.referral_cashback.referral.title"
  )}, ${t("players.table.column.email")}, ${t(
    "players.table.column.phone"
  )}, ${t("players.table.column.userid")}, ${t("player.profile.doc-id")}`;

  const handleAccountStatusSelect = useCallback(
    (status: AccountStatus) => {
      onChange({
        accountStatus: status !== "ALL" ? (status as UserStatus) : undefined,
      });
    },
    [onChange]
  );

  const handleBaseCurrencySelect = useCallback(
    (base_currency: Currency) => {
      onBaseCurrencyChange(base_currency);
    },
    [onBaseCurrencyChange]
  );

  const handleSearch = useCallback(
    (value: string) => {
      if (value) {
        onChange({
          searchQuery: value,
        });
      } else {
        onChange({
          searchQuery: undefined,
        });
      }
    },
    [onChange]
  );

  const handleSearchQueryChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (!value) {
        handleSearch(e.currentTarget.value);
      }
    },
    [handleSearch]
  );

  const handleCalendarChange = useCallback(
    (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
      if (!dates) {
        onChange({
          registrationDate: undefined,
        });
        return;
      }
      const [from, to] = dates;
      onChange({
        registrationDate: {
          from: from?.toISOString(),
          to: to?.endOf("D").toISOString(),
        },
      });
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    onChange({
      registrationDate: undefined,
    });
  }, [onChange]);

  const handleChangeProtectedTag = useCallback(
    (value: string) => {
      setSelectedProtectedTag(value);
      if (!value) {
        onChange({
          tags: selectedChangeableTag ? [selectedChangeableTag] : [],
        });
        return;
      }
      onChange({
        tags: selectedChangeableTag ? [selectedChangeableTag, value] : [value],
      });
    },
    [onChange, selectedChangeableTag]
  );

  const handleChangeChangeableTag = useCallback(
    (value: string) => {
      setSelectedChangeableTag(value);
      if (!value) {
        onChange({
          tags: selectedProtectedTag ? [selectedProtectedTag] : [],
        });
        return;
      }
      onChange({
        tags: selectedProtectedTag ? [selectedProtectedTag, value] : [value],
      });
    },
    [onChange, selectedProtectedTag]
  );

  return (
    <Form
      className="players-filter"
      layout="inline"
      initialValues={{
        reg_date: [
          registrationDateFrom ? dayjs(registrationDateFrom) : null,
          registrationDateTo ? dayjs(registrationDateTo) : null,
        ],
      }}
    >
      <Form.Item label={t("players.filter.account-status.label")}>
        <AccountStatusSelect
          onChange={handleAccountStatusSelect}
          defaultValue={accountStatus}
        />{" "}
      </Form.Item>
      <Tooltip
        placement={"top"}
        trigger={"click"}
        color="white"
        overlayInnerStyle={{ color: "black" }}
        title={`${t("common.search-tooltip")}: ${tooltipText}`}
      >
        <Form.Item>
          <Search
            placeholder={t("common.search")}
            style={{ width: 180 }}
            onSearch={handleSearch}
            onChange={handleSearchQueryChange}
            loading={false}
          />
        </Form.Item>
      </Tooltip>
      <Form.Item label={t("players.table.column.base_currency")}>
        <BaseCurrencySelect
          onChange={handleBaseCurrencySelect}
          defaultValue={baseCurrency}
        />{" "}
      </Form.Item>

      <Form.Item
        label={t("players.filter.registration-date.label")}
        name="reg_date"
      >
        <RangePicker
          size={"small"}
          placeholder={[
            t("common.placeholder.start-date"),
            t("common.placeholder.end-date"),
          ]}
          onCalendarChange={handleCalendarChange}
        />
      </Form.Item>
      <Form.Item>
        <Select
          onChange={handleChangeChangeableTag}
          removeIcon
          style={{ minWidth: 120, width: "auto" }}
          defaultValue=""
        >
          <Option value="">{t("players.filter.custom-tags")}</Option>
          {changeableUserTags.map((tag) => (
            <Option value={tag.tag} key={tag.tag}>
              {tag.tag}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Select
          onChange={handleChangeProtectedTag}
          removeIcon
          style={{ minWidth: 180, width: "auto" }}
          defaultValue=""
        >
          <Option value="">{t("players.filter.system-tags")}</Option>
          {protectedUserTags.map((tag) => (
            <Option value={tag.tag} key={tag.tag}>
              {tag.tag}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={t("players-activity.filter-label.deposits-approved")}>
        <Select
          onChange={(value) => onChange({ depositsApprovedCount: value })}
          removeIcon
          style={{ minWidth: 100, width: "auto" }}
          defaultValue={DepositApprovedCount.ALL}
        >
          {DEPOSITS_APPROVED_OPTIONS.map((option) => (
            <Option value={option.value} key={option.value}>
              {t(option.label)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button onClick={handleClear} type="primary" ghost>
          <ReloadOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};

type AccountStatus = UserStatus | "ALL";
type IAccountStatusProps = {
  onChange: (status: AccountStatus) => void;
  defaultValue?: UserStatus;
};

type IBaseCurrencyProps = {
  onChange: (base_currency: Currency) => void;
  defaultValue?: Currency;
};

const AccountStatusSelect: FC<IAccountStatusProps> = ({
  defaultValue,
  onChange,
}) => {
  const [t] = useTranslation();
  return (
    <Select
      onChange={onChange}
      style={{ width: 100 }}
      removeIcon
      defaultValue={defaultValue || "ALL"}
    >
      <Option value={"ALL"}>
        {t("players.filter.account-status.options.all")}
      </Option>
      <Option value={UserStatus.Draft}>
        {t("players.filter.account-status.options.draft")}
      </Option>
      <Option value={UserStatus.Registered}>
        {t("players.filter.account-status.options.registered")}
      </Option>
      <Option value={UserStatus.Blocked}>
        {t("players.filter.account-status.options.blocked")}
      </Option>
      <Option value={UserStatus.Internal}>
        {t("players.filter.account-status.options.internal")}
      </Option>
    </Select>
  );
};

const BaseCurrencySelect: FC<IBaseCurrencyProps> = ({
  defaultValue,
  onChange,
}) => {
  return (
    <CurrencySelect
      onChange={onChange}
      style={{ width: 80 }}
      removeIcon
      defaultValue={defaultValue}
    />
  );
};
