import React, { FC, useCallback, useContext } from "react";
import { IUserSummaryProjection } from "@finbackoffice/adminbff-client";
import { notification, Table, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { Currency, UserStatus } from "@finbackoffice/enums";
import {
  CurrencyFormatterContext,
  DEFAULT_PLAYERS_SORTING,
  getOriginalSortingFromTable,
  IPagination,
  ISorting,
  PlayerStatus,
  IconUser,
  useUserModal,
  usePermissions,
} from "@finbackoffice/backoffice-core-next";
import SportIcon from "../../../icons/soccer.svg";
import CasinoIcon from "../../../icons/casino.svg";
import { Order } from "@finbackoffice/http-client";
import { SorterResult } from "antd/lib/table/interface";
import { usePathname } from "next/navigation";
import { InfoCircleOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";

const { Column } = Table;
const { Paragraph } = Typography;
const COLUMN_CONFIG = {
  sorter: true,
};

type IPlayersTableProps = {
  currency: Currency;
  players: Partial<IUserSummaryProjection>[];
  sorting: ISorting<IUserSummaryProjection>;
  onTableChange: (
    pagination: IPagination,
    sorting: ISorting<IUserSummaryProjection>
  ) => void;
  pagination: IPagination;
  isTopSearch: boolean;
};

export const PlayersTable: FC<IPlayersTableProps> = ({
  currency,
  sorting,
  players,
  onTableChange,
  pagination,
  isTopSearch = false,
}) => {
  const { t } = useTranslation();
  const { isRestrictedUser } = usePermissions();
  const { openUserModal } = useUserModal();
  const pathname = usePathname();
  const { formatCurrency } = useContext(CurrencyFormatterContext);
  const nf = new Intl.NumberFormat("en-US", {
    useGrouping: true,
  });

  const getDefaultSortingOrder = useCallback(
    (fieldName: keyof IUserSummaryProjection) => {
      const order = sorting.order === Order.ASC ? "ascend" : "descend";
      return sorting.field === fieldName ? order : undefined;
    },
    [sorting]
  );

  const renderRegistrationDateHandler = useCallback(
    (value: string, record: IUserSummaryProjection) => {
      return record.created_at
        ? moment(record.created_at).format("MM/DD/YY HH:mm")
        : "";
    },
    []
  );

  const onCellClickHandler = useCallback(
    (data: IUserSummaryProjection) => ({
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        openUserModal({ user_id: data.user_id, redirect: pathname });
      },
    }),
    [openUserModal, pathname]
  );

  const handleOnCopy = useCallback(
    (fieldName: string) => {
      notification.success({
        message: `${fieldName} ${t("cashier.table.copy-to-clipboard.message")}`,
      });
    },
    [t]
  );

  return (
    <>
      <Table<Partial<IUserSummaryProjection>>
        dataSource={players}
        showSorterTooltip={false}
        onChange={({ current, pageSize }, f, s) =>
          onTableChange(
            { current: current!, pageSize: pageSize! },
            (s as SorterResult<IUserSummaryProjection>).order
              ? getOriginalSortingFromTable({
                  field: (s as SorterResult<IUserSummaryProjection>)
                    .field as keyof IUserSummaryProjection,
                  order: (s as SorterResult<IUserSummaryProjection>).order!,
                })
              : DEFAULT_PLAYERS_SORTING
          )
        }
        pagination={pagination}
        bordered
        scroll={{ y: "calc(100% - 35px)" }}
        rowKey="user_id"
      >
        <Column
          sorter={COLUMN_CONFIG.sorter}
          title={<IconUser />}
          dataIndex="status"
          className="center"
          defaultSortOrder={getDefaultSortingOrder("status")}
          render={(status: UserStatus) => (
            <span title={status}>
              <PlayerStatus status={status} />
            </span>
          )}
          key="status"
          width="40px"
        />
        {isTopSearch ? null : (
          <Column
            sorter={COLUMN_CONFIG.sorter}
            title={t("players.table.column.created_at")}
            dataIndex="created_at"
            defaultSortOrder={getDefaultSortingOrder("created_at")}
            render={renderRegistrationDateHandler}
            width="60px"
            key="created_at"
          />
        )}
        <Column
          sorter={COLUMN_CONFIG.sorter}
          title={t("players.table.column.userid")}
          dataIndex="user_id"
          className="center"
          defaultSortOrder={getDefaultSortingOrder("user_id")}
          key="userid"
          width="70px"
        />
        <Column
          sorter={COLUMN_CONFIG.sorter}
          title={t("players.table.column.username")}
          dataIndex="username"
          defaultSortOrder={getDefaultSortingOrder("username")}
          key="username"
          width="130px"
          onCell={onCellClickHandler}
          className="table-username-td"
        />
        {isTopSearch ? null : (
          <>
            <Column
              sorter={COLUMN_CONFIG.sorter}
              title={t("players.table.column.email")}
              dataIndex="email"
              defaultSortOrder={getDefaultSortingOrder("email")}
              key="email"
              width="140px"
            />
            <Column
              title={t("players.table.column.ref_code")}
              dataIndex="referral_slug"
              defaultSortOrder={getDefaultSortingOrder("referral_slug")}
              key="referral_slug"
              width="106px"
            />
            <Column
              title={t("players.table.column.currency")}
              dataIndex="currency"
              defaultSortOrder={getDefaultSortingOrder("currency")}
              key="currency"
              width="46px"
            />
          </>
        )}
        <Column
          sorter={COLUMN_CONFIG.sorter}
          title={t("players.table.column.balance")}
          dataIndex="balance"
          defaultSortOrder={getDefaultSortingOrder("balance")}
          render={(value: string, record: IUserSummaryProjection) =>
            formatCurrency(record.balance, currency)
          }
          key="balance"
          width="90px"
        />
        {isTopSearch ? null : (
          <Column
            sorter={COLUMN_CONFIG.sorter}
            title={t("players.table.column.month_profit")}
            dataIndex="month_profit"
            defaultSortOrder={getDefaultSortingOrder("month_profit")}
            key="month_profit"
            render={(value: string, record: IUserSummaryProjection) => (
              <>
                <span
                  className={classNames({
                    "players-highlighted-data": Number(record.month_profit) < 0,
                  })}
                >
                  {formatCurrency(record.month_profit, currency)}
                </span>
                {Number(record.month_profit) !== 0 && (
                  <Tooltip
                    placement={"right"}
                    overlayClassName="info-tooltip"
                    title={
                      <ul>
                        <li className="flex">
                          <SportIcon />
                          <div>
                            {formatCurrency(
                              record.sport_month_profit,
                              currency
                            )}
                          </div>
                        </li>
                        <li>
                          <li className="flex">
                            <CasinoIcon />
                            <div>
                              {formatCurrency(
                                record.casino_month_profit,
                                currency
                              )}
                            </div>
                          </li>
                        </li>
                      </ul>
                    }
                  >
                    <i className="info-profit">D</i>
                  </Tooltip>
                )}
              </>
            )}
            width="90px"
          />
        )}
        {!isRestrictedUser && (
          <Column
            sorter={COLUMN_CONFIG.sorter}
            title={t("players.table.column.total_profit")}
            dataIndex="total_profit"
            defaultSortOrder={getDefaultSortingOrder("total_profit")}
            key="total_profit"
            render={(value: string, record: IUserSummaryProjection) => (
              <>
                <span
                  className={classNames({
                    "players-highlighted-data": Number(record.total_profit) < 0,
                  })}
                >
                  {formatCurrency(record.total_profit, currency)}
                </span>
                {Number(record.total_profit) !== 0 && (
                  <Tooltip
                    placement={"right"}
                    overlayClassName="info-tooltip"
                    title={
                      <ul>
                        <li className="flex">
                          <SportIcon />
                          <div>
                            {formatCurrency(
                              record.sport_total_profit,
                              currency
                            )}
                          </div>
                        </li>
                        <li>
                          <li className="flex">
                            <CasinoIcon />
                            <div>
                              {formatCurrency(
                                record.casino_total_profit,
                                currency
                              )}
                            </div>
                          </li>
                        </li>
                      </ul>
                    }
                  >
                    <i className="info-profit">D</i>
                  </Tooltip>
                )}
              </>
            )}
            width="96px"
          />
        )}
        {isTopSearch ? null : (
          <>
            <Column
              title={t("players.table.column.last_bet")}
              dataIndex="last_bet"
              key="last_bet"
              width="70px"
            />
            <Column
              sorter={COLUMN_CONFIG.sorter}
              title={t("players.table.column.last_login")}
              dataIndex="last_login_at"
              key="last_login_at"
              width="70px"
              render={(value: string) => {
                return value ? moment(value).format("MM/DD/YY HH:mm") : "";
              }}
            />
            <Column
              sorter={COLUMN_CONFIG.sorter}
              title={t("players.table.column.name")}
              dataIndex="first_name"
              defaultSortOrder={getDefaultSortingOrder("first_name")}
              key="name"
              width="100px"
              render={(value, record: IUserSummaryProjection) => {
                return value ? `${value} ${record.last_name}` : "";
              }}
            />
            <Column
              sorter={COLUMN_CONFIG.sorter}
              title={t("players.table.column.phone")}
              dataIndex="phone"
              defaultSortOrder={getDefaultSortingOrder("phone")}
              key="phone"
              width="110px"
            />
            <Column
              className="players-table-notes"
              title={t("players.table.column.notes")}
              dataIndex="notes"
              defaultSortOrder={getDefaultSortingOrder("notes")}
              key="notes"
              render={(value) => (
                <Paragraph
                  ellipsis={{ rows: 2, expandable: false, tooltip: true }}
                >
                  {!!value && value[0]}
                </Paragraph>
              )}
            />
          </>
        )}
        <Column
          sorter={COLUMN_CONFIG.sorter}
          title={t("players.table.column.referral")}
          dataIndex="referral"
          defaultSortOrder={getDefaultSortingOrder("referral")}
          key="referral"
          width="106px"
          render={(value, record: IUserSummaryProjection) => {
            if (value && record.tracker) return `${value} / ${record.tracker}`;
            return !!value ? value : record.tracker;
          }}
        />
        {isTopSearch ? null : (
          <>
            <Column
              title={t("players.table.column.last_login_ip")}
              dataIndex="last_login_ip"
              key="last_login_ip"
              width="110px"
              render={(value, record: IUserSummaryProjection) => {
                return (
                  <div className="last-login-ip">
                    <span>{value}</span>
                    {record?.users_same_ip?.length > 0 && (
                      <Tooltip
                        placement={"right"}
                        overlayClassName="info-tooltip"
                        title={
                          <ul>
                            {[...new Set(record.users_same_ip)].map(
                              (id, index) => (
                                <li className="flex" key={index}>
                                  ID:
                                  <CopyToClipboard
                                    text={`${id}`}
                                    onCopy={() =>
                                      handleOnCopy(
                                        t("player.profile.header.id")
                                      )
                                    }
                                  >
                                    <span>{id}</span>
                                  </CopyToClipboard>
                                </li>
                              )
                            )}
                          </ul>
                        }
                      >
                        <InfoCircleOutlined
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              }}
            />
            <Column
              colSpan={2}
              title={t("players.table.column.brand")}
              dataIndex="brand"
              key="brand"
              width="90px"
              render={(_value, record: IUserSummaryProjection) => {
                return `${
                  record.last_login_origin?.replace(/^https?:\/\//, "") ?? ""
                }`;
              }}
            />
          </>
        )}
        {isTopSearch ? (
          <Column
            colSpan={2}
            title={t("players.table.column.mode")}
            dataIndex="mode"
            key="mode"
            width="64px"
            render={() => t("common.player")}
          />
        ) : null}
      </Table>
      <div className="players-total-records">{`${t(
        "players.table.total-records"
      )}: ${nf.format(Number(pagination.total))}`}</div>
    </>
  );
};
