import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Spin } from "antd";
import { IFormValues } from "@finbackoffice/backoffice-core-next";

type ILoginForm = {
  onSubmit: (values: IFormValues) => void;
  onChanges: () => void;
  spinning: boolean;
};

export const LoginForm: FC<ILoginForm> = ({
  onSubmit,
  onChanges,
  spinning,
}) => {
  const { t } = useTranslation();

  const onFinish = (values: IFormValues) => {
    onSubmit(values);
  };

  const onError = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onError}
      autoComplete="on"
      onChange={onChanges}
    >
      <Spin spinning={spinning}>
        <p className="login-title">Login to your account</p>
        <div className="form-content">
          <Form.Item
            name="email"
            className="login-email"
            rules={[
              {
                required: true,
                message: t("login.login-form.email.error.required"),
              },
            ]}
          >
            <Input
              placeholder={t("login.login-form.email.label")}
              data-testid="email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="login-password"
            rules={[
              {
                required: true,
                message: t("login.login-form.password.error.required"),
              },
            ]}
          >
            <Input.Password
              placeholder={t("login.login-form.password.label")}
              data-testid="password"
            />
          </Form.Item>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="submit"
          className="login-btn"
        >
          {t("login.login-form.button.text")}
        </Button>
      </Spin>
    </Form>
  );
};
