"use client";

import React, { FC } from "react";
import {
  CurrencyFormatterProvider,
  BreadcrumbsProvider,
  WebsocketProvider,
  PersistentStorageProvider,
  TotalProvider,
  PaymentsConfigProvider,
  ProfileInfoProvider,
} from "@finbackoffice/backoffice-core-next";
import { Currency } from "@finbackoffice/enums";
import {
  IPaymentsCurrencyConfig,
  IPaymentsWithdrawalConfig,
} from "@finbackoffice/adminbff-client";
import { PageLayout } from "@/screens/layout";

export type IWithdrawal24H = IPaymentsWithdrawalConfig;

export type IPaymentsCurrencyConfigWithCurrency = (
  | IPaymentsCurrencyConfig
  | undefined
) & {
  currency: Currency;
};

export const LoggedInProviders: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <WebsocketProvider>
      <PaymentsConfigProvider>
        <ProfileInfoProvider>
          <TotalProvider>
            <CurrencyFormatterProvider>
              <PersistentStorageProvider>
                <BreadcrumbsProvider>
                  <PageLayout>{children}</PageLayout>
                </BreadcrumbsProvider>
              </PersistentStorageProvider>
            </CurrencyFormatterProvider>
          </TotalProvider>
        </ProfileInfoProvider>
      </PaymentsConfigProvider>
    </WebsocketProvider>
  );
};
