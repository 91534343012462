import Search from "antd/lib/input/Search";
import { Modal, Radio, RadioChangeEvent } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IconClose,
  IPagination,
  usePermissions,
} from "@finbackoffice/backoffice-core-next";
import Players from "@/screens/players-control/players/Players";
import BetsComponent from "@/screens/reports/bets/BetsComponent";
import "./header-search.sass";

const RadioGroup = Radio.Group;

type IHeaderSearchProps = {};

const DEFAULT_PAGINATION: IPagination = {
  current: 1,
  pageSize: 10,
};

export const HeaderSearch: React.FC<IHeaderSearchProps> = () => {
  const { t } = useTranslation();
  const { isRestrictedUser } = usePermissions();
  const optionsWithDisabled = [
    { label: t("player.profile.tab.bets"), value: "bets" },
    { label: t("sidebar.player"), value: "player" },
  ];
  const [searchBy, setSearchBy] = useState<string>(
    !isRestrictedUser
      ? optionsWithDisabled[0].value
      : optionsWithDisabled[1].value,
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    isRestrictedUser && setSearchBy(optionsWithDisabled[1].value);
  }, [isRestrictedUser]);

  const handleSearchChange = useCallback((value: string) => {
    setShowModal(true);
  }, []);

  return (
    <div className="top-header-search">
      <Search
        onSearch={handleSearchChange}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      <RadioGroup
        options={optionsWithDisabled}
        onChange={onChange}
        value={searchBy}
        disabled={isRestrictedUser}
      />
      <Modal
        open={!!searchValue && showModal}
        closeIcon={<IconClose />}
        width={1350}
        onCancel={() => {
          setShowModal(false);
        }}
        destroyOnClose={true}
        footer={null}
        closable={true}
        className={
          searchBy === "bets" ? "top-search-by-bet" : "top-search-by-player"
        }
      >
        {searchBy === "bets" ? (
          <div className="container">
            <BetsComponent
              showFilters={false}
              search={searchValue}
              topPagination={DEFAULT_PAGINATION}
            />
          </div>
        ) : (
          <Players
            key={searchValue}
            search={searchValue}
            showFilters={false}
            isTopSearch={true}
            topPagination={{
              current: 1,
              pageSize: 10,
            }}
          />
        )}
      </Modal>
    </div>
  );

  function onChange(e: RadioChangeEvent) {
    setSearchBy(e.target.value);
  }
};
